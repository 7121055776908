import { get, post } from "./common/http";
import { BASE_URL } from "./common/const";

export const GetSupplierDetail = (params: any) =>
get(`${BASE_URL}/api/Supplier/GetDetail?` + params, null);

export const GetSupplierListForSelect = (params: any) =>
  get(`${BASE_URL}/api/Supplier/GetListForSelect?` + params, null);

export const GetSupplierList = (params: any) =>
get(`${BASE_URL}/api/Supplier/GetList?` + params ,null);

export const GetSupplierListForFieldSelect = (params: any) =>
get(`${BASE_URL}/api/Supplier/GetSupplierListForFieldSelect?` + params ,null);
  
export const CreateSupplier = (data:any) =>
post(`${BASE_URL}/api/Supplier/Create`, data);
  
export const DeleteSupplier = (data: any) =>
post(`${BASE_URL}/api/Supplier/Delete`, data);

export const UpdateSuplier = (data: any) =>
post(`${BASE_URL}/api/Supplier/Update`, data);    

export const GetSupplierListForPayment = (params: any) =>
get(`${BASE_URL}/api/Supplier/GetSupplierListForPayment?` + params ,null);

