import { get, post } from "./common/http";
import { BASE_URL } from "./common/const";

export const GetBuyerDetail = (params: any) =>
  get(`${BASE_URL}/api/Buyer/GetDetail?` + params, null);

export const GetBuyerList = (params: any) =>
  get(`${BASE_URL}/api/Buyer/GetList?` + params, null);
  export const GetBuyerListForArea = (params: any) =>
  get(`${BASE_URL}/api/Buyer/GetListForArea?` + params, null);

export const GetBuyerListForReceipt = (params: any) =>
  get(`${BASE_URL}/api/Buyer/GetBuyerListForReceipt?` + params, null);

export const GetBuyerListForFieldSelect = (params: any) =>
  get(`${BASE_URL}/api/Buyer/GetBuyerListForFieldSelect?` + params, null);

export const CreateBuyer = (data: any) =>
  post(`${BASE_URL}/api/Buyer/Create`, data);

export const DeleteBuyer = (data: any) =>
  post(`${BASE_URL}/api/Buyer/Delete`, data);

export const UpdateBuyer = (data: any) =>
  post(`${BASE_URL}/api/Buyer/Update`, data);    