import { get, post } from "./common/http";
import { BASE_URL } from "./common/const";

export const GetSaleRegisterList = (params: any) =>
  get(`${BASE_URL}/api/Register/GetSalesRegisterList?` + params, null);

export const GetPurchaseRegisterList = (params: any) =>
  get(`${BASE_URL}/api/Register/GetPurchaseRegisterList?` + params, null);

export const GetOutstandingReceivableList = (params: any) =>
  get(`${BASE_URL}/api/Register/OutstandingReceivableList?` + params, null);

export const GetOutstandingReceivableListNew = (params: any) =>
  get(`${BASE_URL}/api/Register/OutstandingReceivableListNew?` + params, null);

export const GetOutStandingPayableList = (params: any) =>
  get(`${BASE_URL}/api/Register/OutStandingPayableList?` + params, null);


export const GetCashBookPartyWiseList = (params: any) =>
  get(`${BASE_URL}/api/Register/CashbookPartyWise?` + params, null);
