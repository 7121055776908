// function getEnvironment() {

//     // let releaseChannel = manifest?.releaseChannel;

//     //DEV END SETTINGS
//     //no releaseChannel (is undefined) in dev

//     return {
//         envName: "LOCAL",
//         //dbUrl: "http://132.148.132.243:5002",
//         //dbUrl: "http://132.148.132.243:7002",
//         dbUrl: "http://localhost:27909",

//         pagesize: 20,
//         pagesizes: 5,
//     };


//     //STAGING ENV SETTINGS

//     // matches staging-v1, staging-v2
//     // if (releaseChannel.indexOf("staging") !== -1) {
//     //     return {
//     //         envName: "STAGING",
//     //         dbUrl: "https://api.watchgamefilm.com",
//     //         replaceUrl: `exp.host/@tririd/WatchGameFilm?release-channel=staging-v${Constants.manifest?.version}&`,
//     //     };
//     // }

//     // //PROD ENV SETTINGS
//     // // matches prod-v1, prod-v2, prod-v3
//     // if (releaseChannel.indexOf("prod") !== -1) {
//     //     return {
//     //         envName: "PRODUCTION",
//     //         dbUrl: "https://api.watchgamefilm.com",
//     //         replaceUrl: "watchgamefilm://",
//     //     };
//     // }

// }

// let appEnv = getEnvironment();

// export const BASE_URL = appEnv?.dbUrl;
// export const PAGE_SIZE = appEnv?.pagesize;
// export const PAGE_SIZES = appEnv?.pagesizes;
// export const SITE_TITLE = "Billing";
let curMode = window.config.mode;
let apiUrl: any;
if (curMode != undefined && curMode.toLowerCase() === "local") {
    apiUrl = process.env.REACT_APP_API_URL;
} else if (curMode != undefined && curMode.toLowerCase() === "staging") {
    apiUrl = process.env.REACT_APP_STAGING_API_URL;
} else if (curMode != undefined && curMode.toLowerCase() === "production") {
    apiUrl = process.env.REACT_APP_PROD_API_URL;
}

export const BASE_URL = apiUrl;
export const PAGE_SIZE = +process.env.REACT_APP_PRIMARY_PAGE_SIZE!;
export const PAGE_SIZES = +process.env.REACT_APP_SECONDARY_PAGE_SIZE!;
export const SITE_TITLE = process.env.REACT_APP_SITE_TITLE;
export const MAINTENANCE_URL = "../../../maintenance.html";