import * as React from "react";
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    IconButton,
    TextField,
    Grid,
    MenuItem,
    styled,
    MenuProps,
    alpha,
    Menu,
    InputAdornment,
    Pagination,
    Stack,
    Backdrop,
    CircularProgress,
    useTheme,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import { useLocation, useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import { useEffect, useState } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import AddIcon from "@mui/icons-material/Add";
import { GetList, GetStockBatchWiseList, getStockReportExportFile } from '../../../Services/StockService'
import { PAGE_SIZE } from "../../../Services/common/const";
import Title from "../../../Components/Title";
import { PreferencesContext } from "../../../PreferencesContext";
import MissingConsumer from "../../../Components/MissingConsumer";
import { ExportFilesFunction, numberFormat } from "../../../shared/CommonFuctions";
import { KeyboardArrowDownSharp } from "@mui/icons-material";
import { GetStockBatchWiseListReport, GetStockReport } from "../../../Services/ReportService";
import KeyboardBackspaceRoundedIcon from '@mui/icons-material/KeyboardBackspaceRounded';
import SplashComponent from "../../../Navigation/splash";
import { AlertType } from "../../../shared/Modal/AlertType";
import { GetCategoryList } from "../../../Services/Category";
import MultiSelectSim from "../../../Components/MultiSelectSimple";
import { EncryptDecryptService } from "../../../Services/common/EncryptionService";
import { GetProductListforBatchwiseListReport } from "../../../Services/CategoryProductService";
import { format } from "date-fns";
import { clearScreenDown } from "readline";






interface Data {
    id: number;
    code: string;
    Product: string;
    Availability: string;
    none: string;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = "asc" | "desc" | "";

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key
): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string }
) => number {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort<T>(
    array: readonly T[],
    comparator: (a: T, b: T) => number
) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

interface EnhancedTableProps {
    numSelected: number;
    order: Order;
    orderBy: String;
    rowCount: number;
}


function EnhancedTableHead(props: EnhancedTableProps) {

    return (
        // checkBox for Header
        <TableHead>
            <TableRow>
                <TableCell
                    sx={{
                        display: {
                            xs: "table-cell",
                            sm: "table-cell",
                            md: "table-cell",
                            lg: "table-cell",
                        },
                        fontWeight: "bold",
                        minWidth: "140px",
                    }}
                    align="left"
                >
                    {"Product"}
                </TableCell>
                <TableCell
                    sx={{
                        display: {
                            xs: "none",
                            sm: "table-cell",
                            md: "table-cell",
                            lg: "table-cell",
                        },
                        fontWeight: "bold",
                        minWidth: "80px",
                    }}
                    align="left"
                >
                    {"Unit"}
                </TableCell>
                <TableCell
                    sx={{
                        display: {
                            xs: "none",
                            sm: "table-cell",
                            md: "table-cell",
                            lg: "table-cell",
                        },
                        fontWeight: "bold",
                        minWidth: "80px",
                    }}
                    align="left"
                >
                    {"Category"}
                </TableCell>
                <TableCell
                    sx={{
                        display: {
                            xs: "none",
                            sm: "table-cell",
                            md: "table-cell",
                            lg: "table-cell",
                        },
                        fontWeight: "bold",
                        minWidth: "80px",
                    }}
                    align="left"
                >
                    {"Warehouse"}
                </TableCell>

                <TableCell
                    sx={{
                        display: {
                            xs: "none",
                            sm: "table-cell",
                            md: "table-cell",
                            lg: "table-cell",
                        },
                        fontWeight: "bold",
                        minWidth: "80px",
                    }}
                    align="left"
                >
                    {"Batch"}
                </TableCell>
                <TableCell
                    sx={{
                        display: {
                            xs: "none",
                            sm: "table-cell",
                            md: "table-cell",
                            lg: "table-cell",
                        },
                        fontWeight: "bold",
                        minWidth: "80px",
                    }}
                    align="center"
                >
                    {"Expiry"}
                </TableCell>
                <TableCell
                    sx={{
                        display: {
                            xs: "none",
                            sm: "table-cell",
                            md: "table-cell",
                            lg: "table-cell",
                        },
                        fontWeight: "bold",
                        minWidth: "80px",
                    }}
                    align="right"
                >
                    {"Opening"}
                </TableCell>
                <TableCell
                    sx={{
                        display: {
                            xs: "none",
                            sm: "table-cell",
                            md: "table-cell",
                            lg: "table-cell",
                        },
                        fontWeight: "bold",
                        minWidth: "80px",
                    }}
                    align="right"
                >
                    {"Inward"}
                </TableCell>

                <TableCell
                    sx={{
                        display: {
                            xs: "none",
                            sm: "table-cell",
                            md: "table-cell",
                            lg: "table-cell",
                        },
                        fontWeight: "bold",
                        minWidth: "80px",
                    }}
                    align="right"
                >
                    {"Outward"}
                </TableCell>

                <TableCell
                    sx={{
                        display: {
                            xs: "table-cell",
                            sm: "table-cell",
                            md: "table-cell",
                            lg: "table-cell",
                        },
                        fontWeight: "bold",
                        minWidth: "80px",
                    }}
                    align="right"
                >
                    {"Available"}
                </TableCell>
            </TableRow>
        </TableHead>
    );
}

const stock: any = {
    batchWiseListCollection: [],
};
const parameters: any = {
    consumerId: 25,
    branchId: 1,
    pageIndex: 1,
    pageSize: PAGE_SIZE,
    sortColumn: "",
    sortDirection: "",
    searchValue: "",
    lessThanValue: "",
};

export default function StockByProductReport() {
    const Stockdata = {
        title: "Stock",
        button: "Save",
        stockID: 0,
    };
    const theme = useTheme();
    const matches = useMediaQuery("(max-width:599px)");
    const match = useMediaQuery("(max-width:599px)");
    const mobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

    let navigate = useNavigate();
    const [state, setState] = useState(Stockdata);
    const [param, setParam] = useState(parameters);
    const [anchorEl, setAnchorEl3] = React.useState<HTMLButtonElement | null>(
        null
    );
    const [stockID, setstockID] = React.useState(null);
    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;
    const [order, setOrder] = React.useState<Order>("");
    const [orderBy, setOrderBy] = React.useState<keyof Data>("none");
    const [selected, setSelected] = React.useState<readonly number[]>(
        stock.batchWiseListCollection
    );
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(PAGE_SIZE);
    const [value, setValue] = useState("");
    const [values, setValues] = useState("");
    const [isLoading, setLoading] = React.useState(false);
    const [isAllowAdd, setAllowAdd] = React.useState(true);
    const [isSubmitting, setSubmitting] = React.useState(false);
    const [openDilog, setopenDilog] = React.useState(false);
    const [resultData, setResultData] = useState(stock);
    const [selectedProductIdData, setselectedProductIdData] = React.useState<any>([]);
    const [productCollection, setProductCollection] = useState<any>([]);
    const [page_Index, setPage_Index] = useState(1);
    const [counData, setCounData] = useState(0);
    const [anchorEl2, setAnchorEl2] = React.useState<null | HTMLElement>(null);
    const [Curr_page, setCurrPage] = React.useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const [pageStartIndex, setPageStartIndex] = useState(1);
    const [pageEndIndex, setPageEndIndex] = useState(1);
    // const [categorysSelectedOption, setCategorysSelectedOption] = React.useState<any>([]);
    const [productsSelectedOption, setProductssSelectedOption] = React.useState<any>([]);

    const openExportType = Boolean(anchorEl2);
    const handleClickExportType = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl2(event.currentTarget);
    };
    const handleCloseExportType = () => {
        setAnchorEl2(null);
    };
    const { userContext, updateUserContext } =
        React.useContext(PreferencesContext);

    async function fetchStockList() {
        //new http for API
        setLoading(true);
        let params = new URLSearchParams();
        params.append("consumerId", userContext.consumerId.toString());
        params.append("firmID", userContext.firmId.toString());
        params.append("branchID", userContext.branchId.toString());
        params.append("pageIndex", page_Index.toString());
        params.append("pageSize", param.pageSize);
        params.append("sortColumn", param.sortColumn);
        params.append("sortDirection", param.sortDirection);
        params.append("searchValue", param.searchValue);
        params.append("lessThanValue", param.lessThanValue);
        params.append("financialYear", userContext.financialYear);
        if (convertedProductId > 0 && selectedProductIdData?.length === 0 && productsSelectedOption?.length !== 0) {
            params.append("productID", convertedProductId.toString());
        }
        else {
            params.append("productID", selectedProductIdData);
        }

        try {
            const result: any = await GetStockBatchWiseList(params);
            let endIndex: any;
            if (result && result.data.data) {
                let dataCount = result.data.data.totalCount;
                let StockInfo = result.data.data;
                let showDataCount = Math.ceil(dataCount / PAGE_SIZE);
                setResultData(StockInfo);
                setCounData(showDataCount);
                let startIndex = (page_Index - 1) * param.pageSize + 1;
                endIndex =
                    (page_Index - 1) * param.pageSize +
                    (result.data.data.batchWiseListCollection
                        ? result.data.data.batchWiseListCollection.length
                        : 0);
                setPageStartIndex(startIndex);
                setPageEndIndex(endIndex);
                setTotalCount(dataCount);

            } else {
                console.log("Error");
            }
            setLoading(false);
        } catch (e: any) {
            updateUserContext({
                ...userContext,
                isAlert: true,
                alertMessage: typeof e === 'string' ? e as string : e?.message as string,
                alertType: AlertType.Error,
            });
        }
    }


    // async function GetProductListforBatchwise() {
    //      
    //     try {
    //         let params = new URLSearchParams();
    //         params.append("consumerId", userContext.consumerId.toString());
    //         params.append("firmId", userContext.firmId.toString());
    //         params.append("branchId", userContext.branchId.toString());
    //         params.append("sortColumn", param.sortColumn);
    //         params.append("sortDirection", param.sortDirection);
    //         params.append("searchValue", param.searchValue);

    //         const result: any = await GetProductListforBatchwiseList(params);
    //         console.log(result)
    //         if (result?.productCollection) {
    //             setProductCollection(result?.productCollection);
    //         } else {
    //         }
    //     } catch (e: any) {
    //         updateUserContext({
    //             ...userContext,
    //             isAlert: true,
    //             alertMessage: typeof e === 'string' ? e as string : e?.message as string,
    //             alertType: AlertType.Error,
    //         });
    //     }
    // }


    async function GetProductListforBatchwise() {
        setLoading(true);
        let params = new URLSearchParams();
        params.append("consumerId", userContext.consumerId.toString());
        params.append("firmId", userContext.firmId.toString());
        params.append("branchId", userContext.branchId.toString());
        params.append("sortColumn", param.sortColumn);
        params.append("sortDirection", param.sortDirection);
        params.append("searchValue", param.searchValue);
        try {
            let categoryInfo: any;
            const result: any = await GetProductListforBatchwiseListReport(params);
            if (result && result.data.data) {
                categoryInfo = result.data?.data?.productCollection;
                setProductCollection(categoryInfo)
            } else {
            }
            // setLoading(false);
            return categoryInfo
        } catch (e: any) {
            setLoading(false);
            updateUserContext({
                ...userContext,
                isAlert: true,
                alertMessage: typeof e === 'string' ? e as string : e?.message as string,
                alertType: AlertType.Error,
            });
        }
    }

    const isSelected = (id: number) => selected.indexOf(id) !== -1;

    function searchValue(val: any) {
        if (val === "") {
            setValue("");
        }
        setPage_Index(1);
        setParam({ ...param, searchValue: val.toString(), pageIndex: 1 });
    }

    //for less than
    function lessThanValue(val: any) {
        if (val === "") {
            setValues("");
        }
        setPage_Index(1);
        setParam({ ...param, lessThanValue: val.toString(), pageIndex: 1 });
    }


    const handleChangePageNew = (
        event: React.ChangeEvent<unknown>,
        value: number
        //values: number
    ) => {
        if (Curr_page !== value) {
            setCurrPage(value);
            setPage_Index(value);
            setParam({ ...param, pageIndex: value });
        }
    };

    const StyledMenu = styled((props: MenuProps) => (
        <Menu
            elevation={0}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            {...props}
        />
    ))(({ theme }) => ({
        "& .MuiPaper-root": {
            borderRadius: 6,
            marginTop: theme.spacing(1),
            minWidth: 50,
            color:
                theme.palette.mode === "light"
                    ? "rgb(55, 65, 81)"
                    : theme.palette.grey[300],
            boxShadow:
                "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
            "& .MuiMenu-list": {
                padding: "4px 0",
            },
            "& .MuiMenuItem-root": {
                "& .MuiSvgIcon-root": {
                    fontSize: 18,
                    color: theme.palette.text.secondary,
                    marginRight: theme.spacing(1.5),
                },
                "&:active": {
                    backgroundColor: alpha(
                        theme.palette.primary.main,
                        theme.palette.action.selectedOpacity
                    ),
                },
            },
        },
    }));

    async function openStockReportPopup(type: any) {

        setAnchorEl2(null);
        setLoading(true);
        let params = new URLSearchParams();
        params.append("reportName", "BatchWiseStockReport");
        params.append("reportType", type);
        params.append("consumerId", userContext.consumerId.toString());
        params.append("branchId", userContext.branchId.toString());
        params.append("firmId", userContext.firmId.toString());
        params.append("searchValue", param.searchValue);
        params.append("lessThanValue", param.lessThanValue);
        params.append("productId", selectedProductIdData);
        params.append("financialYear", userContext.financialYear.toString());
        try {
            const result: any = await GetStockBatchWiseListReport(params);
            if ((result.data.data, type === "pdf")) {
                var pdfContent = result.data.data.fileData;
                let fileName = "BatchWiseStockReport" + ".pdf";
                var mediaType = `data:application/pdf;df:` + fileName + `;base64,`;
                const linkSource = `${mediaType}${pdfContent}`;
                const downloadLink = document.createElement("a");
                downloadLink.href = linkSource;
                downloadLink.download = fileName;
                downloadLink.click();
            } else {
                var excelContent = result.data.data.fileData;
                var resultReport =
                    "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,";
                const linkSource = `${resultReport}${excelContent}`;
                const downloadLink = document.createElement("a");
                let fileName = "BatchWiseStock_Report" + ".xls";

                downloadLink.href = linkSource;
                downloadLink.download = fileName;
                downloadLink.click();
            }

            if (result && result.data && result.data.data) {
                var fileContent = result?.data?.data?.fileData;
                var attachmentData = fileContent;
                var attachmentName = ''
                if (type === 'pdf') {
                    attachmentName = "BatchWiseStockReport.pdf"
                }
                else {
                    attachmentName = "BatchWiseStockReport.xls"
                }
                ExportFilesFunction(attachmentName, type, attachmentData)
                setLoading(false);
            }
            setLoading(false);
        } catch (e: any) {
            // setLoading(false);
            updateUserContext({
                ...userContext,
                isAlert: true,
                alertMessage: typeof e === 'string' ? e as string : e?.message as string,
                alertType: AlertType.Error,
            });
        }
    }

    function useQuery() {
        const { search } = useLocation();

        return React.useMemo(() => new URLSearchParams(search), [search]);
    }

    let urlParams = useQuery();
    const edService = new EncryptDecryptService();
    let tempEncryptedConvertTimeCategoryId = urlParams.get("q");
    let decryptProductId: any = edService.decrypt(tempEncryptedConvertTimeCategoryId);
    let convertedProductId: any = parseInt(decryptProductId) || 0;
    // convertedProductId
    const handleChangeCategory = (object: any) => {
        setProductssSelectedOption(object);
        let tempProductIdData: any = [];
        object.forEach((x) => {
            if (x?.value === "*") {
                return;
            } else {
                let productIds = x.productId;
                x.productId = productIds;
                tempProductIdData.push(productIds.toString());
            }
        });
        setselectedProductIdData(tempProductIdData)
    };



    // async function handleMoveToStock(productId: any) {
    //     try {
    //         let encProductId: any = edService.encrypt(productId);
    //         navigate({
    //             pathname: "/stock",
    //             search: `?q=${encodeURIComponent(encProductId)}`,
    //         }, {
    //             state: { title: "Edit Category", button: "Update", productId: productId, isAddPopUp: "false" },
    //         });
    //     } catch (e: any) {
    //         updateUserContext({ ...userContext, isAlert: true, alertMessage: typeof e === 'string' ? e as string : e?.message as string, alertType: AlertType.Error });
    //     }
    // }

    useEffect(() => {
        GetProductListforBatchwise();
        fetchStockList();
        setSelected([]);
        let e: any = document.getElementById("root");
        e.scrollIntoView({
            block: "start",
            behavior: "smooth",
            inline: "start",
        });
    }, [param, selectedProductIdData]);


    useEffect(() => {

        const productId = async () => {

            const productData = await GetProductListforBatchwise()
            if (convertedProductId > 0 && convertedProductId) {
                const filteroption: any = (productData?.filter((x) => { return x.productId === convertedProductId }))
                setProductssSelectedOption(filteroption);
                setselectedProductIdData(convertedProductId.toString());
                handleChangeCategory(filteroption)
            } else {
            }
        }

        productId()
    }, [])


    return (
        <>
            <Backdrop
                sx={{
                    color: "#fff",
                    backgroundColor: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={isLoading}
            >
                <SplashComponent />
            </Backdrop>
            <Title pageTitle="batch-wise-stock" />


            {userContext.consumerId > 0 ? (
                <Box
                    className="boxContainer"
                    sx={{
                        "& > :not(style)": {
                            width: "100%",
                        },
                    }}
                >
                    <Grid
                        className="title-button-responsive"
                        item
                        xs={6}
                        md={3}
                        sx={{
                            display: "flex !important",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <h4 className="page-title">Batch Wise Stock</h4>
                        <Grid
                            item
                            xs={6}
                            md={3}
                            marginRight={0}
                            textAlign="end"
                            paddingTop="0px"
                        >
                            <Button
                                // color="primary"
                                variant="outlined"
                                onClick={() => navigate(-1)}
                                size="medium"
                                disabled={!isAllowAdd}
                                startIcon={<KeyboardBackspaceRoundedIcon />}
                            >
                                Back
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid className="paper top-space">
                        <Paper elevation={4} className="paper-container">
                            <Grid item xs={12} sm={4} md={3} lg={2} xl={2}>
                                <MultiSelectSim
                                    key="productId"
                                    placeholder="Select Product.."
                                    options={productCollection}
                                    onChange={handleChangeCategory}
                                    value={productsSelectedOption}
                                    isSelectAll={true}
                                    menuPlacement={"bottom"}
                                />
                                {/* <MultiSelect
                  placeholderLabel="select category"
                  options={productCollection}
                  defaultOptions={categorysSelectedOption}
                  onChange={handleChangeCategory}
                /> */}
                            </Grid>
                            <Grid
                                container
                                spacing={1}
                                sx={{ marginTop: "10px" }}
                                className="search-space"
                            >
                                <Grid item xs={12} sm={4} md={3} lg={2} xl={2} >
                                    <TextField
                                        onKeyPress={(e) => {
                                            if (e.key === "Enter") {
                                                searchValue(value);
                                            }
                                        }}
                                        sx={{ paddingLeft: "0px !important", width: '100%' }}
                                        className="button-responsive-stock"
                                        placeholder="Search..."
                                        type="text"
                                        variant="outlined"
                                        size="small"
                                        onChange={(e) => setValue(e.target.value)}
                                        value={value}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton onClick={() => searchValue(value)}>
                                                        <SearchIcon />
                                                    </IconButton>
                                                </InputAdornment>
                                            ),

                                            endAdornment: value && (
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => searchValue("")}
                                                >
                                                    <ClearIcon />
                                                </IconButton>
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4} md={3} lg={2} xl={2} >
                                    <TextField
                                        onKeyPress={(e) => {
                                            if (e.key === "Enter") {
                                                lessThanValue(values);
                                            }
                                        }}
                                        sx={{ width: '100%' }}
                                        className="button-responsive-stock"
                                        placeholder="Stock is less than..."
                                        type="number"
                                        variant="outlined"
                                        size="small"
                                        onChange={(e) => setValues(e.target.value)}
                                        value={values}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton onClick={() => lessThanValue(values)}>
                                                        <SearchIcon />
                                                    </IconButton>
                                                </InputAdornment>
                                            ),

                                            endAdornment: values && (
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => lessThanValue("")}
                                                >
                                                    <ClearIcon />
                                                </IconButton>
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4} md={6} lg={8} xl={8}>
                                    <Grid
                                        sx={{
                                            height: "100%",
                                            display: "flex",
                                            justifyContent: "end",
                                            alignItems: "center",
                                        }}>
                                        <Button
                                            className="button-responsive-stock"
                                            aria-controls={
                                                openExportType ? "demo-customized-menu" : undefined
                                            }
                                            aria-haspopup="true"
                                            aria-expanded={openExportType ? "true" : undefined}
                                            disabled={
                                                resultData?.batchWiseListCollection.length <= 0 ? true : false
                                            }
                                            variant="outlined"
                                            //size="large"
                                            //sx={{ height: "43px" }}
                                            disableElevation
                                            onClick={handleClickExportType}
                                            startIcon={<DownloadIcon />}
                                            endIcon={<KeyboardArrowDownSharp />}
                                        >
                                            Export
                                        </Button>
                                        <StyledMenu
                                            id="demo-customized-menu"
                                            MenuListProps={{
                                                "aria-labelledby": "demo-customized-button",
                                            }}
                                            anchorEl={anchorEl2}
                                            open={openExportType}
                                            onClose={handleCloseExportType}
                                        >
                                            <MenuItem
                                                disableRipple
                                                onClick={() => {
                                                    openStockReportPopup("pdf");
                                                }}
                                            >
                                                PDF
                                            </MenuItem>
                                            <MenuItem
                                                disableRipple
                                                onClick={() => {
                                                    openStockReportPopup("Excel");
                                                }}
                                            >
                                                EXCEL
                                            </MenuItem>
                                        </StyledMenu>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <TableContainer>
                                <Table
                                    aria-labelledby="tableTitle"
                                    size={dense ? "small" : "medium"}
                                >
                                    <EnhancedTableHead
                                        numSelected={selected.length}
                                        order={order}
                                        orderBy={orderBy}
                                        rowCount={resultData?.batchWiseListCollection.length}
                                    />
                                    <TableBody>
                                        {stableSort(
                                            resultData?.batchWiseListCollection,
                                            getComparator(order, orderBy)
                                        )
                                            .slice(
                                                page * rowsPerPage,
                                                page * rowsPerPage + rowsPerPage
                                            )
                                            .map((item: any, index: any) => {
                                                const isItemSelected = isSelected(item.stockID);
                                                const labelId = `enhanced-table-checkbox-${index}`;
                                                return (
                                                    <TableRow
                                                        hover
                                                        role="checkbox"
                                                        aria-checked={isItemSelected}
                                                        tabIndex={-1}
                                                        key={index + 1}
                                                        selected={isItemSelected}
                                                    >
                                                        <TableCell
                                                            component="th"
                                                            id={labelId}
                                                            scope="row"
                                                            align="left"
                                                        >
                                                            <span >
                                                                {item.productName}
                                                            </span>
                                                            {/* :
                                                            <span className='text-link' style={{ color: "#5156BE " }}>
                                                                {item?.name}
                                                            </span> */}
                                                        </TableCell>


                                                        <TableCell
                                                            sx={{
                                                                display: {
                                                                    xs: "none",
                                                                    sm: "table-cell",
                                                                    md: "table-cell",
                                                                },
                                                            }}
                                                            align="left"
                                                        >
                                                            {item?.unitName}
                                                        </TableCell>

                                                        <TableCell
                                                            sx={{
                                                                display: {
                                                                    xs: "none",
                                                                    sm: "table-cell",
                                                                    md: "table-cell",
                                                                },
                                                            }}
                                                            align="left"
                                                        >
                                                            {item?.categoryName}
                                                        </TableCell>

                                                        <TableCell
                                                            sx={{
                                                                display: {
                                                                    xs: "none",
                                                                    sm: "table-cell",
                                                                    md: "table-cell",
                                                                },
                                                            }}
                                                            align="left"
                                                        >
                                                            {item?.warehouseName}
                                                        </TableCell>

                                                        <TableCell
                                                            sx={{
                                                                display: {
                                                                    xs: "none",
                                                                    sm: "table-cell",
                                                                    md: "table-cell",
                                                                },
                                                            }}
                                                            align="left"
                                                        >
                                                            {item?.batchNumber}
                                                        </TableCell>

                                                        <TableCell
                                                            sx={{
                                                                display: {
                                                                    xs: "none",
                                                                    sm: "table-cell",
                                                                    md: "table-cell",
                                                                },
                                                            }}
                                                            align="center"
                                                        >
                                                            {/* {
                                                                item?.expiryDate && (
                                                                    format(
                                                                        new Date(item.expiryDate),
                                                                        "dd-MM-yyyy"
                                                                    )
                                                                )
                                                            } */}

                                                            {item?.expiryDate}
                                                        </TableCell>

                                                        <TableCell
                                                            sx={{
                                                                display: {
                                                                    xs: "none",
                                                                    sm: "table-cell",
                                                                    md: "table-cell",
                                                                },
                                                            }}
                                                            align="right"
                                                        >
                                                            {numberFormat(item?.openingQuantity)}
                                                        </TableCell>

                                                        <TableCell
                                                            sx={{
                                                                display: {
                                                                    xs: "none",
                                                                    sm: "table-cell",
                                                                    md: "table-cell",
                                                                },
                                                            }}
                                                            align="right"
                                                        >
                                                            {numberFormat(item.inward)}
                                                        </TableCell>

                                                        <TableCell
                                                            sx={{
                                                                display: {
                                                                    xs: "none",
                                                                    sm: "table-cell",
                                                                    md: "table-cell",
                                                                },
                                                            }}
                                                            align="right"
                                                        >
                                                            {numberFormat(item.outward)}
                                                        </TableCell>
                                                        <TableCell
                                                            sx={{
                                                                display: {
                                                                    xs: "table-cell",
                                                                    sm: "table-cell",
                                                                    md: "table-cell",
                                                                },
                                                            }}
                                                            align="right"
                                                            className={
                                                                item?.availabelQuantity <= 0
                                                                    ? "red-color"
                                                                    : item?.availabelQuantity <= 10
                                                                        ? "blue-color"
                                                                        : ""
                                                            }
                                                        >
                                                            {numberFormat(item?.availabelQuantity)}
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        {resultData?.batchWiseListCollection.length > 0 && (
                                            <TableRow hover tabIndex={-1} key={"0"}>
                                                <TableCell className="bold-600">Total</TableCell>

                                                <TableCell align="right" className="bold-600 double-underline"

                                                    sx={{
                                                        display: {
                                                            xs: "none",
                                                            sm: "table-cell",
                                                            md: "table-cell",
                                                        },
                                                    }}
                                                >
                                                </TableCell>
                                                <TableCell align="right" className="bold-600 double-underline"

                                                    sx={{
                                                        display: {
                                                            xs: "none",
                                                            sm: "table-cell",
                                                            md: "table-cell",
                                                        },
                                                    }}
                                                >
                                                </TableCell>
                                                <TableCell align="right" className="bold-600 double-underline"

                                                    sx={{
                                                        display: {
                                                            xs: "none",
                                                            sm: "table-cell",
                                                            md: "table-cell",
                                                        },
                                                    }}
                                                >
                                                </TableCell>
                                                <TableCell align="right" className="bold-600 double-underline"

                                                    sx={{
                                                        display: {
                                                            xs: "none",
                                                            sm: "table-cell",
                                                            md: "table-cell",
                                                        },
                                                    }}
                                                >
                                                    { }
                                                    {/* {numberFormat(resultData?.batchWiseListCollection?.reduce((acc, curr) => acc + (curr?.batchNumber ? curr?.batchNumber : 0), 0))} */}
                                                </TableCell>
                                                <TableCell align="right" className="bold-600 double-underline"
                                                    sx={{
                                                        display: {
                                                            xs: "none",
                                                            sm: "table-cell",
                                                            md: "table-cell",
                                                        },
                                                    }}
                                                >
                                                    {""}
                                                    {/* {numberFormat(resultData?.batchWiseListCollection?.reduce((acc, curr) => acc + (curr?.expiryDate ? curr?.expiryDate : 0), 0))} */}
                                                </TableCell>
                                                <TableCell align="right" className="bold-600 double-underline"
                                                    sx={{
                                                        display: {
                                                            xs: "none",
                                                            sm: "table-cell",
                                                            md: "table-cell",
                                                        },
                                                    }}
                                                >
                                                    {numberFormat(resultData?.batchWiseListCollection?.reduce((acc, curr) => acc + (curr?.openingQuantity ? curr?.openingQuantity : 0), 0))}
                                                </TableCell>
                                                <TableCell align="right" className="bold-600 double-underline"
                                                    sx={{
                                                        display: {
                                                            xs: "none",
                                                            sm: "table-cell",
                                                            md: "table-cell",
                                                        },
                                                    }}
                                                >
                                                    {numberFormat(resultData?.batchWiseListCollection?.reduce((acc, curr) => acc + (curr?.inward ? curr?.inward : 0), 0))}
                                                </TableCell>
                                                <TableCell align="right" className="bold-600 double-underline"
                                                    sx={{
                                                        display: {
                                                            xs: "none",
                                                            sm: "table-cell",
                                                            md: "table-cell",
                                                        },
                                                    }}
                                                >
                                                    {numberFormat(resultData?.batchWiseListCollection?.reduce((acc, curr) => acc + (curr?.outward ? curr?.outward : 0), 0))}
                                                </TableCell>
                                                <TableCell align="right" className="bold-600 double-underline">
                                                    {numberFormat(resultData?.batchWiseListCollection?.reduce((acc, curr) => acc + (curr?.availabelQuantity ? curr?.availabelQuantity : 0), 0))}
                                                </TableCell>
                                            </TableRow>
                                        )}
                                        {resultData?.batchWiseListCollection.length <= 0 && (
                                            <TableRow hover tabIndex={-1} key={"0"}>
                                                <TableCell colSpan={6} align="center">
                                                    No record(s) found
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                                {
                                    resultData?.batchWiseListCollection?.length > 0 &&
                                    <Grid
                                        container
                                        paddingTop={1}
                                        alignItems={"center"}
                                        justifyContent={"center"}
                                    >
                                        <Grid item xs={12} sm={6}
                                            className="pagination-label"
                                        >
                                            {pageStartIndex} - {pageEndIndex} of {totalCount}
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Stack
                                                component="div"
                                                alignItems={mobileScreen ? " center" : "end"}
                                                justifyContent={"center"}
                                            >
                                                <Pagination
                                                    color="primary"
                                                    count={counData}
                                                    page={page_Index}
                                                    onChange={handleChangePageNew}
                                                />
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                }
                            </TableContainer>
                        </Paper>
                    </Grid>
                </Box>
            ) : (
                <Box
                    className="boxContainer"
                    sx={{
                        "& > :not(style)": {
                            width: "100%",
                        },
                    }}
                >
                    <MissingConsumer consumerId={userContext.consumerId} />
                </Box>
            )}
        </>
    );
}
