import { get, post } from "./common/http";
import { BASE_URL } from "./common/const";

export const GetSaleDetail = (params: any) =>
  get(`${BASE_URL}/api/Sale/GetDetail?` + params, null);
export const GetCustomSaleDetail = (params: any) =>
  get(`${BASE_URL}/api/CustomSale/GetCustomDetail?` + params, null);
export const GetCustomQuotatoinDetail = (params: any) =>
  get(`${BASE_URL}/api/CustomQuotation/GetCustomDetailQuotation?` + params, null);

export const GetLastSaleDetail = (params: any) =>
  get(`${BASE_URL}/api/Sale/GetLastDetail?` + params, null);

export const GetSaleDetailForCredit = (params: any) =>
  get(`${BASE_URL}/api/Sale/GetSaleDetailForCredit?` + params, null);

export const GetSaleDetailByBuyerAndProduct = (params: any) =>
  get(`${BASE_URL}/api/Sale/GetSaleDetailByBuyer?` + params, null);

export const GetSaleList = (params: any) =>
  get(`${BASE_URL}/api/Sale/GetList?` + params, null);

export const GetSaleListByBuyer = (params: any) =>
  get(`${BASE_URL}/api/Sale/GetListByBuyer?` + params, null);

export const GetPrintInvoice = (params: any) =>
  get(`${BASE_URL}/api/Sale/GetPrintInvoice?` + params, null);

export const GetPrintProformaInvoice = (params: any) =>
  get(`${BASE_URL}/api/Report/GetPrintProformaInvoice?` + params, null);

export const GetPrintQuotationInvoice = (params: any) =>
  get(`${BASE_URL}/api/Report/GetPrintQuotationInvoice?` + params, null);

export const GetPrintLabourInvoice = (params: any) =>
  get(`${BASE_URL}/api/Labour/GetPrintLabourInvoice?` + params, null);

export const CreateSale = (data: any) =>
  post(`${BASE_URL}/api/Sale/Create`, data, true);
export const CreateCustomSale = (data: any) =>
  post(`${BASE_URL}/api/CustomSale/CreateCustomSale`, data);
export const CreateCustomQuotation = (data: any) =>
  post(`${BASE_URL}/api/CustomQuotation/CreateCustomQuotation`, data);


export const UpdateSale = (data: any) =>
  post(`${BASE_URL}/api/Sale/Update`, data, true);
export const UpdateCustomSale = (data: any) =>
  post(`${BASE_URL}/api/CustomSale/UpdateCustomSale`, data);
export const UpdateCustomQuotation = (data: any) =>
  post(`${BASE_URL}/api/CustomQuotation/UpdateCustomQuotation`, data);


export const getInvoiceReportExportFile = (params) =>
  get(`${BASE_URL}/api/Sale/InvoiceExportFile?` + params, null);

export const GetStaffListForInvoice = (params: any) =>
  get(`${BASE_URL}/api/staff/GetStaffListForInvoice?` + params, null);

export const GetProductListBySaleId = (params: any) =>
  get(`${BASE_URL}/api/Product/GetListBySaleId?` + params, null);

export const GetInvoiceNumber = (params: any) =>
  get(`${BASE_URL}/api/Sale/GetInvoiceNumber?` + params, null);

export const GetProformaInvoiceNumber = (params: any) =>
  get(`${BASE_URL}/api/Proforma/GetInvoiceNumber?` + params, null);

export const GetProformaDetail = (params: any) =>
  get(`${BASE_URL}/api/Proforma/GetDetail?` + params, null);

export const GetLastProformaDetail = (params: any) =>
  get(`${BASE_URL}/api/Proforma/GetLastDetail?` + params, null);

export const GetProformaList = (params: any) =>
  get(`${BASE_URL}/api/Proforma/GetList?` + params, null);

export const CreateProforma = (data: any) =>
  post(`${BASE_URL}/api/Proforma/Create`, data, true);

export const UpdateProforma = (data: any) =>
  post(`${BASE_URL}/api/Proforma/Update`, data, true);
export const CreateProformaLWH = (data: any) =>
  post(`${BASE_URL}/api/Proforma/CreateLWH`, data);

export const UpdateProformaLWH = (data: any) =>
  post(`${BASE_URL}/api/Proforma/UpdateLWH`, data);

export const DeleteProforma = (data: any) =>
  post(`${BASE_URL}/api/Proforma/Delete`, data);

export const GetQuotationInvoiceNumber = (params: any) =>
  get(`${BASE_URL}/api/Quotation/GetInvoiceNumber?` + params, null);

export const GetQuotationDetail = (params: any) =>
  get(`${BASE_URL}/api/Quotation/GetDetail?` + params, null);

export const GetLastQuotationDetail = (params: any) =>
  get(`${BASE_URL}/api/Quotation/GetLastDetail?` + params, null);

export const GetQuotationList = (params: any) =>
  get(`${BASE_URL}/api/Quotation/GetList?` + params, null);

export const CreateQuotation = (data: any) =>
  post(`${BASE_URL}/api/Quotation/Create`, data, true);

export const UpdateQuotation = (data: any) =>
  post(`${BASE_URL}/api/Quotation/Update`, data, true);

export const DeleteQuotation = (data: any) =>
  post(`${BASE_URL}/api/Quotation/Delete`, data);

export const GetLabourInvoiceNumber = (params: any) =>
  get(`${BASE_URL}/api/Labour/GetInvoiceNumber?` + params, null);

export const GetLabourDetail = (params: any) =>
  get(`${BASE_URL}/api/Labour/GetDetail?` + params, null);

export const GetLastLabourDetail = (params: any) =>
  get(`${BASE_URL}/api/Labour/GetLastDetail?` + params, null);

export const GetLabourList = (params: any) =>
  get(`${BASE_URL}/api/Labour/GetList?` + params, null);

export const CreateLabour = (data: any) =>
  post(`${BASE_URL}/api/Labour/Create`, data, true);
export const UpdateLabour = (data: any) =>
  post(`${BASE_URL}/api/Labour/Update`, data, true);

export const CreateLabourLWH = (data: any) =>
  post(`${BASE_URL}/api/Labour/CreateLWH`, data);

export const UpdateLabourLWH = (data: any) =>
  post(`${BASE_URL}/api/Labour/UpdateLWH`, data);

export const DeleteLabour = (data: any) =>
  post(`${BASE_URL}/api/Labour/Delete`, data);

export const GetSaleListForInvoiceReceipt = (params: any) =>
  get(`${BASE_URL}/api/Sale/GetSaleListForInvoiceReceipt?` + params, null);

export const GetQuotationDetailData = (params: any) =>
  get(`${BASE_URL}/api/Quotation/GetQuotationDetailData?` + params, null);

export const DeleteSale = (data: any) =>
  post(`${BASE_URL}/api/Sale/Delete`, data);

export const GetAdvancePaymentListForSale = (params: any) =>
  get(`${BASE_URL}/api/Sale/GetAdvancePaymentListForSale?` + params, null);

export const GetSaleSummaryBySalePerson = (params: any) =>
  get(`${BASE_URL}/api/Sale/SaleDetailbySaleperson?` + params, null);

export const GetSaleSummaryByCustomer = (params: any) =>
  get(`${BASE_URL}/api/Sale/GetSaleSummaryByCustomer?` + params, null);

export const GetSaleDetailsByCustomerWise = (params: any) =>
  get(`${BASE_URL}/api/Sale/GetSaleDetailsByCustomer?` + params, null);

export const GetSaleSummaryByProduct = (params: any) =>
  get(`${BASE_URL}/api/Sale/GetSaleSummaryByProduct?` + params, null);

export const GetSaleDetailsByProduct = (params: any) =>
  get(`${BASE_URL}/api/Sale/GetSaleDetailsByProduct?` + params, null);
