import { format, setDate } from "date-fns";
import { InvoiceType, JEType } from "./Modal/GSTType";
import { AdvanceAndReferenceTypes } from "../StaticData/common"
import { useLocation } from "react-router-dom";
import React from "react";

export function contactFormat(value: any) {
  if (!value) return;
  value = value.replace(/^(\d{5})/, "$1 ");
  return value;
}

export function numberFormat(item: any) {
  return new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  })
    .format(item)
    .replace(/₹/g, "");
}

export function numberFormatWithCrDr(item: any, nature: string) {

  let formattedNumber = '';
  nature = (nature === 'CR') ? 'Cr' : 'Dr';

  nature = (item < 0) ? ((nature === 'Cr') ? 'Dr' : 'Cr') : nature;

  item = Math.abs(item)
  formattedNumber = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  })
    .format(item)
    .replace(/₹/g, "");

  formattedNumber = formattedNumber + ' ' + nature;
  return formattedNumber;
}

export function getSingleSelected(selectedValue: any, sourceList: any) {
  let result = sourceList.find((o) => o.value === selectedValue);
  return result;
}

export function numDifferentiation(value, decimalFixes) {
  let result = value.toFixed(decimalFixes);
  let val = Math.abs(value);
  if (val >= 10000000) {
    result = (val / 10000000).toFixed(decimalFixes);
  } else if (val >= 100000) {
    result = (val / 100000).toFixed(decimalFixes);
  } else if (val >= 1000) {
    result = (val / 1000).toFixed(decimalFixes);
  } else {
    result = val.toFixed(decimalFixes);
  }

  if (value < 0) {
    return "-₹" + result;
  } else {
    return "₹" + result;
  }
}

export function numDifferentiationForText(value) {
  let result = "";
  let val = Math.abs(value);
  if (val >= 10000000) {
    result = "Crores";
  } else if (val >= 100000) {
    result = "Lakhs";
  } else if (val >= 1000) {
    result = "Thousands";
  } else {
    result = "Rupees";
  }
  return result;
}

export function getCurrentFinancialYear() {
  let financialYear = "";
  let startYear = 0;
  let endYear = 0;
  let docDate = new Date();

  if (new Date().getMonth() > 2) {
    startYear = docDate.getFullYear();
    endYear = docDate.getFullYear() + 1;
    let tempFinancialYear = startYear + "-" + endYear;
    financialYear = tempFinancialYear;
  } else {
    startYear = docDate.getFullYear() - 1;
    endYear = docDate.getFullYear();
    let tempFinancialYear = startYear + "-" + endYear;
    financialYear = tempFinancialYear;
  }
  return financialYear;
}

export function getCurrentFinancialYearStartDate() {
  let startYearDate = new Date();
  let startYear = 0;
  let docDate = new Date();
  if (new Date().getMonth() > 2) {
    startYear = docDate.getFullYear();
    startYearDate = new Date(startYear, 3, 1);
  } else {
    startYear = docDate.getFullYear() - 1;
    startYearDate = new Date(startYear, 3, 1);
  }
  let finalstartYearDate = format(new Date(startYearDate), "yyyy-MM-dd");
  return finalstartYearDate;
}

export function getCurrentFinancialYearEndDate() {
  let endYearDate = new Date();
  let endYear = 0;
  let docDate = new Date();
  if (new Date().getMonth() > 2) {
    endYear = docDate.getFullYear() + 1;
    endYearDate = new Date(endYear, 2, 31);
  } else {
    endYear = docDate.getFullYear();
    endYearDate = new Date(endYear, 2, 31);
  }
  let finalendYearDate = format(new Date(endYearDate), "yyyy-MM-dd");
  return finalendYearDate;
}

export function getStartDate() {
  let startDate = new Date();
  if (startDate.getMonth() === 3) {
    startDate = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
  } else {
    startDate = new Date(
      new Date().getFullYear(),
      new Date().getMonth() - 1,
      1
    );
  }
  return startDate;
}

export function getEndDate() {
  let endDate = new Date();
  if (endDate.getMonth() === 3) {
    endDate = new Date();
  } else {
    endDate.setDate(0);
  }
  return endDate;

}

export function getSaleType(value: any) {
  let result: any = InvoiceType.AllInvoice;
  let val = value.toLowerCase();
  if (val === "tax-invoice") {
    result = InvoiceType.TaxInvoice;
  } else if (val === "proforma-invoice") {
    result = InvoiceType.Proforma;
  } else if (val === "quotation-estimate") {
    result = InvoiceType.Quotation;
  } else if (val === "labour-invoice") {
    result = InvoiceType.LabourInvoice;
  } else if (val === "composition-invoice") {
    result = InvoiceType.CompositionInvoice;
  }
  return result;
}

export function getJournalEnteriesType(value: any) {
  let result: any = JEType.AllJE;
  let val = value.toLowerCase();
  if (val === "payment") {
    result = JEType.Payment;
  } else if (val === "receipt") {
    result = JEType.Receipt
  } else if (val === "contra") {
    result = JEType.Contra;
  } else if (val === "journal") {
    result = JEType.JournalVoucher;
  }
  return result;
}
export function getJournalEnteriesTypePageName(value: any) {
  let result: any = "JE";
  let val = value.toLowerCase();
  if (val === "payment") {
    result = "Payment ";
  } else if (val === "receipt") {
    result = "Receipt ";
  } else if (val === "contra") {
    result = "Contra ";
  } else if (val === "journal") {
    result = "Journal ";
  }
  return result;
}
export function getJournalEnteriesCapitalizeType(value: any) {
  let result: any = "JE";
  let val = value.toLowerCase();
  if (val === "payment") {
    result = "VOUCHER_PAYMENT";
  } else if (val === "receipt") {
    result = "VOUCHER_RECEIPT";
  } else if (val === "contra") {
    result = "VOUCHER_CONTRA";
  } else if (val === "journal") {
    result = "VOUCHER_JOURNAL";
  }
  return result;
}

export function getSaleTypePageName(value: any) {
  let result: any = "Invoices";
  let val = value.toLowerCase();
  if (val === "tax-invoice") {
    result = "Invoices (GST)";
  } else if (val === "proforma-invoice") {
    result = "Proforma Invoices";
  } else if (val === "quotation-estimate") {
    result = "Quotation/Estimates";
  } else if (val === "labour-invoice") {
    result = "Labour Invoices";
  } else if (val === "composition-invoice") {
    result = "Invoices";
  }
  return result;
}


export function getSaleSaveTypePageName(value: any) {
  let result: any = "Invoices";
  let val = value.toLowerCase();
  if (val === "tax-invoice") {
    result = "Invoice (GST)";
  } else if (val === "proforma-invoice") {
    result = "Proforma Invoice";
  } else if (val === "quotation-estimate") {
    result = "Quotation/Estimates";
  } else if (val === "labour-invoice") {
    result = "Labour Invoice";
  } else if (val === "composition-invoice") {
    result = "Invoice";
  }
  return result;
}

export function getSaleTypeName(value: any) {
  let result: any = "Invoices";
  let val = value.toLowerCase();
  if (val === "tax-invoice") {
    result = "SALE";
  } else if (val === "proforma-invoice") {
    result = "PROFORMA";
  } else if (val === "quotation-estimate") {
    result = "QUOTATION";
  } else if (val === "labour-invoice") {
    result = "LABOUR";
  } else if (val === "composition-invoice") {
    result = "SALE";
  }
  return result;
}

export function getNameSelected(selectedValue: any, sourceList: any) {
  let result = sourceList.find((o: { value: any; }) => o.value === selectedValue);
  return (result === undefined || result === "" ? "" : result.label);
}

export const convertToUpperCase = (value: any) => {
  return value?.toString()?.toUpperCase();
}

export function getMonthStartAndEndDate(month: number, year: number) {
  if (month < 1 || month > 12) {
    throw new Error("Invalid month number. Month number should be between 1 and 12.");
  }

  const startDate = new Date(year, month - 1, 1); // Subtract 1 from month to adjust for zero-based indexing
  const endDate = new Date(year, month, 0); // Day 0 of the next month is the last day of the current month

  return {
    startDate,
    endDate,
  };
}

export function isElementVisible(element) {
  return element.offsetWidth > 0 || element.offsetHeight > 0;
}

export function ExportFilesFunction(attachmentName: string, attachmentType: string, attachmentData: any,) {
  let iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window?.MSStream;
  let isChrome: any =
    navigator.userAgent.toLowerCase().indexOf("CriOS") > -1 ||
    navigator.vendor.toLowerCase().indexOf("google") > -1;
  let iOSVersion: any = [];
  if (iOS) {
    iOSVersion = navigator?.userAgent
      .match(/OS [\d_]+/i)[0]
      .substr(3)
      .split("_")
      .map((n) => parseInt(n));
  }
  let contentType = ''
  let blobMediaType = ''
  if (attachmentType === 'pdf') {
    contentType = "application/pdf";
    blobMediaType = `data:application/pdf;df:`;
  }
  else {
    contentType = "application/msexcel";
    blobMediaType = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,`;
  }
  let binary = atob(attachmentData.replace(/\s/g, ""));
  let len = binary.length;
  let buffer = new ArrayBuffer(len);
  let view = new Uint8Array(buffer);
  for (let i = 0; i < len; i++) {
    view[i] = binary.charCodeAt(i);
  }
  let linkElement = document.createElement("a");
  try {
    let hrefUrl = "";
    let blob: any = "";
    if (iOS && !isChrome && iOSVersion[0] <= 12) {
      blob = blobMediaType + attachmentData;
      hrefUrl = blob;
    } else {
      if (iOS && !isChrome) {
        contentType = "application/octet-stream";
      }
      blob = new Blob([view], { type: contentType });
      hrefUrl = window.URL.createObjectURL(blob);
    }
    linkElement.setAttribute("href", hrefUrl);
    linkElement.setAttribute("target", "_blank");
    if ((iOS && (iOSVersion[0] > 12 || isChrome)) || !iOS) {
      linkElement.setAttribute("download", attachmentName);
    }
    let clickEvent = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: false
    });
    linkElement.dispatchEvent(clickEvent);
  } catch (ex) {
    console.log("file export error: ", ex);

  }
}


export function getReferenceAdjustmentType(value: string) {
  const adjRef = AdvanceAndReferenceTypes.find(x => x.value === value);
  return adjRef?.label;
}

export function dataURLtoFile(dataurl, filename) {
  var arr = dataurl?.split(","),
    mime = arr[0]?.match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}


export const handleFocus = (event) => event?.target?.select();


export function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}



export function removeSpacesAndLowercase(input) {
  return input.replace(/\s+/g, '').toLowerCase();
}


export const getStartDateOnInit = (userContext) => {
  const edMonth = new Date(userContext.financialYearEndDate).getMonth() + 1;
  const edYear = new Date(userContext.financialYearEndDate).getFullYear();
  if (userContext.financialYear !== userContext.currentFinancialYear) {
    return new Date(edYear + "-" + edMonth + "-" + "01")
  }
  else {
    return getStartDate()
  }
}
export const getEndDateOnInit = (userContext) => {
  if (userContext.financialYear !== userContext.currentFinancialYear) {
    return new Date(userContext.financialYearEndDate)
  }
  else {
    return getEndDate()
  }
}
