import React, { useState } from 'react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function ScrollToTop() {
    // Extracts pathname property(key) from an object
    const { pathname } = useLocation();
    // Automatically scrolls to top whenever pathname changes
    useEffect(() => {
        let e: any = document.getElementById("root");
        e.scrollIntoView({
            block: 'start',
            behavior: 'smooth',
            inline: 'start'
        })
    }, [pathname]);
    return(
        <>
        </>
    );
}
