import { createTheme } from '@mui/material/styles';
import  variable from './variable.scss';


export const lightTheme = createTheme({
    palette: {
      mode: 'light',
      primary: {
        main: variable.colorPrimary,
      },
      secondary: {
        main: variable.colorSecondary,
      }, 
      background: {
        default: variable.bodyBg
      },
    },
  });
  
   