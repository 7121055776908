export const barcodeTypes = [
    { value: 'UPCA', label: 'UpcA' },//only digits with length 11 or 12 
    { value: 'UPCE', label: 'UpcE' },// length 8 or 12 only and binary only
    { value: 'UPCSUPPLEMENTAL2DIGIT', label: 'UpcSupplemental2Digit' },// lenth 2 with number only
    { value: 'UPCSUPPLEMENTAL5DIGIT', label: 'UpcSupplemental5Digit' },// lenth 5 with number only
    { value: 'EAN13', label: 'Ean13' },// numer only and length 12
    { value: 'EAN8', label: 'Ean8v' },// length 8 and number only
    { value: 'INTERLEAVED2OF5', label: 'Interleaved2Of5' },// length 6 and number only
    { value: 'INTERLEAVED2OF5MOD10', label: 'Interleaved2Of5Mod10' },// length 7 and number only
    { value: 'STANDARD2OF5', label: 'Standard2Of5' },// numbers only and max length is 20 
    { value: 'STANDARD2OF5MOD10', label: 'Standard2Of5Mod10' },//numbers only and max length 19
    { value: 'INDUSTRIAL2OF5', label: 'Industrial2Of5' },//numbers only and max length 20
    { value: 'INDUSTRIAL2OF5MOD10', label: 'Industrial2Of5Mod10' },//numbers only and max length 20
    { value: 'CODE39', label: 'Code39' },//CAPITAL LATERS, number and special charters only(space$ ,% ,+ ,- ,. ,/,*) and max length 20
    { value: 'CODE39EXTENDED', label: 'Code39Extended' },//CAPITAL LATERS, number and special charters only(space$ ,% ,+ ,- ,. ,/,*) and max length 22
    { value: 'CODE39MOD43', label: 'Code39Mod43' },//CAPITAL LATERS, number and special charters only(space$ ,% ,+ ,- ,. ,/,*) and max length 22
    { value: 'CODABAR', label: 'Codabar' },//CAPITAL LATERS, number and special charters only(space$ ,% ,+ ,- ,. ,/,*) and max length 22
    { value: 'POSTNET', label: 'PostNet' },// should be 5, 6, 9, 11 digits
    { value: 'BOOKLAND', label: 'Bookland' },
    { value: 'ISBN', label: 'Isbn' },
    { value: 'JAN13', label: 'Jan13' },
    { value: 'MSIMOD10', label: 'MsiMod10' },
    { value: 'MSI2MOD10', label: 'Msi2Mod10' },
    { value: 'MSIMOD11', label: 'MsiMod11' },
    { value: 'MSIMOD11MOD10', label: 'MsiMod11Mod10' },
    { value: 'MODIFIEDPLESSEY', label: 'ModifiedPlessey' },
    { value: 'CODE1,', label: 'Code11' },
    { value: 'USD8', label: 'Usd8' },
    { value: 'UCC12', label: 'Ucc12' },
    { value: 'UCC13', label: 'Ucc13' },
    { value: 'LOGMARS', label: 'Logmars' },
    { value: 'CODE128', label: 'Code128' },
    { value: 'CODE128A', label: 'Code128A' },
    { value: 'CODE128B', label: 'Code128B' },
    { value: 'CODE128C', label: 'Code128C' },
    { value: 'ITF14', label: 'Itf14' },
    { value: 'CODE93', label: 'Code93' },
    { value: 'TELEPEN', label: 'Telepen' },
    { value: 'FIM', label: 'Fim' },
    { value: 'PHARMACODE', label: 'Pharmacode' }
];

export const AdvanceAndReferenceTypes = [
    // { value: 'NEW_REFERENCE', label: 'New Ref' },
    { value: 'AGAINST_REFERENCE', label: 'Against Ref' },
    { value: 'ADVANCE', label: 'Advance' },
    // { value: 'ON_ACCOUNT', label: 'On Account' }
]
export const parametersForSalePage = {
    pageIndex: 1,
    pageSize: 1000,
    sortColumn: "",
    sortDirection: "",
    searchValue: "",
};

export const parametersForPurchasePage = {
    pageIndex: 1,
    pageSize: 1000,
    sortColumn: "",
    sortDirection: "",
    searchValue: "",
};
export const parametersForChallanPage = {
    pageIndex: 1,
    pageSize: 1000,
    sortColumn: "",
    sortDirection: "",
    searchValue: "",
};

export const quilModules = {
    toolbar: [
        // [{ header: [1, 2, false] }],
        // ["bold", "italic", "underline", "strike", "blockquote"],
        [
            { list: "ordered" },
            { list: "bullet" },
            // { indent: "-1" },
            // { indent: "+1" },
        ],
        [{ size: "small" }],
        // [{ header: [1, 2, 3, 4, 5, 6, false] }],
        // [{ color: [] }, { background: [] }],
        // [{ font: [] }],
        // [{ align: [] }],
        // ["link", "image"],
        // ["clean"],
    ],
};
export const quilFormats = [
    // "list",
    // "header",
    // "bold",
    // "italic",
    // "underline",
    // "strike",
    // "blockquote",
    // "bullet",
    // "indent",
    // "size",
    // "heading",
    // "color",
    // "background",
    // "font",
    // "align",
    // "link",
    // "image",
];


