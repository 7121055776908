import { get, post } from "./common/http";
import { BASE_URL } from "./common/const";

export const GetPrintInvoiceReport = (params: any) =>
    get(`${BASE_URL}/api/Report/GetPrintInvoice?` + params, null);

export const GetPrintCreditNoteReport = (params: any) =>
    get(`${BASE_URL}/api/Report/GetPrintCreditNote?` + params, null);

export const GetPrintDebitNoteReport = (params: any) =>
    get(`${BASE_URL}/api/Report/GetPrintDebitNote?` + params, null);

export const GetDeliveryChallanReport = (params: any) =>
    get(`${BASE_URL}/api/Report/GetDeliveryChallanReport?` + params, null);

export const GetPrintInvoiceReportDownload = (params: any) =>
    get(`${BASE_URL}/api/Report/GetPrintInvoice?` + params, null);

export const GetAreawiseReport = (params: any) =>
    get(`${BASE_URL}/api/Report/AreawiseReport?` + params, null);


export const GetDailySaleReport = (params: any) =>
    get(`${BASE_URL}/api/Report/DailySaleReport?` + params, null);

export const GetDailyPurchaseReport = (params: any) =>
    get(`${BASE_URL}/api/Report/DailyPurchaseReport?` + params, null);

export const GetDailyStaffSummaryReport = (params: any) =>
    get(`${BASE_URL}/api/Report/StaffSummaryReport?` + params, null);

export const GetDailyGSTReport = (params: any) =>
    get(`${BASE_URL}/api/Report/DailyGSTReport?` + params, null);

export const GetGSTR1Report = (params: any) =>
    get(`${BASE_URL}/api/Report/GSTR1Report?` + params, null);

export const GetPurchaseReport = (params: any) =>
    get(`${BASE_URL}/api/Report/PurchaseReport?` + params, null);

export const GetSaleReport = (params: any) =>
    get(`${BASE_URL}/api/Report/SaleReport?` + params, null);

export const GetCreditNoteReport = (params: any) =>
    get(`${BASE_URL}/api/Report/CreditNoteReport?` + params, null);


export const GetDebitNoteReport = (params: any) =>
    get(`${BASE_URL}/api/Report/DebitNoteReport?` + params, null);

export const GetChallanReport = (params: any) =>
    get(`${BASE_URL}/api/Report/ChallanReport?` + params, null);

export const GetSaleRegister = (params: any) =>
    get(`${BASE_URL}/api/Report/SaleRegister?` + params, null);


export const GetOutstandingReceivableReport = (params: any) =>
    get(`${BASE_URL}/api/Report/OutstandingReceivableReport?` + params, null);

export const GetOutstandingReceivableReportNEW = (params: any) =>
    get(`${BASE_URL}/api/Report/OutstandingReceivableReportNew?` + params, null);

export const GetPurchaseRegister = (params: any) =>
    get(`${BASE_URL}/api/Report/PurchaseRegister?` + params, null);

export const GetOutStandingPayableReport = (params: any) =>
    get(`${BASE_URL}/api/Report/OutStandingPayableReport?` + params, null);

export const GetStaffReport = (params: any) =>
    get(`${BASE_URL}/api/Report/StaffReport?` + params, null);

export const GetBuyerReport = (params: any) =>
    get(`${BASE_URL}/api/Report/BuyerReport?` + params, null);

export const GetStockAdjReport = (params: any) =>
    get(`${BASE_URL}/api/Report/StockAdjReport?` + params, null);


export const GetProductReport = (params: any) =>
    get(`${BASE_URL}/api/Report/ProductReport?` + params, null);

export const GetTaxReport = (params: any) =>
    get(`${BASE_URL}/api/Report/TaxReport?` + params, null);

export const GetUnitReport = (params: any) =>
    get(`${BASE_URL}/api/Report/UnitReport?` + params, null);

export const GetFirmReport = (params: any) =>
    get(`${BASE_URL}/api/Report/FirmReport?` + params, null);

export const GetBranchReport = (params: any) =>
    get(`${BASE_URL}/api/Report/BranchReport?` + params, null);

export const GetSupplierReport = (params: any) =>
    get(`${BASE_URL}/api/Report/SupplierReport?` + params, null);

export const GetStockReport = (params: any) =>
    get(`${BASE_URL}/api/Report/StockReport?` + params, null);

// export const GetPrintProformaInvoiceReport = (params: any) =>
//     get(`${BASE_URL}/api/Report/GetPrintProformaInvoice?` + params, null);

export const GetPrintProformaInvoiceReport = (params: any) =>
    get(`${BASE_URL}/api/Report/GetPrintProformaLandScape?` + params, null);

export const GetPrintQuotationInvoiceReport = (params: any) =>
    get(`${BASE_URL}/api/Report/GetPrintQuotationLandScape?` + params, null);

export const GetPrintQuotationLWHInvoiceReport = (params: any) =>
    get(`${BASE_URL}/api/Report/GetPrintQuotationLWHInvoiceReport?` + params, null);

export const GetPrintLabourInvoiceReport = (params: any) =>
    get(`${BASE_URL}/api/Report/GetPrintLabourInvoice?` + params, null);

export const GetProformaReport = (params: any) =>
    get(`${BASE_URL}/api/Report/ProformaReport?` + params, null);

export const GetQuotationReport = (params: any) =>
    get(`${BASE_URL}/api/Report/QuotationReport?` + params, null);

export const GetLabourReport = (params: any) =>
    get(`${BASE_URL}/api/Report/LabourReport?` + params, null);

export const GetPaymentReport = (params: any) =>
    get(`${BASE_URL}/api/Report/PaymentReport?` + params, null);

export const GetPrintPaymentReport = (params: any) =>
    get(`${BASE_URL}/api/Report/GetPrintPayment?` + params, null);

export const GetInvoiceReceiptReport = (params: any) =>
    get(`${BASE_URL}/api/Report/InvoiceReceiptReport?` + params, null);

export const GetPrintInvoiceReceiptReport = (params: any) =>
    get(`${BASE_URL}/api/Report/GetPrintInvoiceReceipt?` + params, null);

export const GetJEReceiptReport = (params: any) =>
    get(`${BASE_URL}/api/Report/JEReceiptReport?` + params, null);

export const GetJEContraReport = (params: any) =>
    get(`${BASE_URL}/api/Report/JEContraReport?` + params, null);

export const GetJEPaymentReport = (params: any) =>
    get(`${BASE_URL}/api/Report/JEPaymentReport?` + params, null);

export const GetJEJournalVoucherReport = (params: any) =>
    get(`${BASE_URL}/api/Report/JEJournalVoucherReport?` + params, null);

// export const GetJournalVoucherReport = (params: any) =>
//     get(`${BASE_URL}/api/Report/JournalVoucherReport?` + params, null);

export const GetStockStatementReport = (params: any) =>
    get(`${BASE_URL}/api/Report/StockStatementReport?` + params, null);

export const GetLandScapePrintInvoiceReport = (params: any) =>
    get(`${BASE_URL}/api/Report/GetPrintInvoiceLandScape?` + params, null);

export const GetLandScapePrintStockAdjReport = (params: any) =>
    get(`${BASE_URL}/api/Report/GetPrintStockAdj?` + params, null);


export const GetLWHPrintInvoiceReport = (params: any) =>
    get(`${BASE_URL}/api/Report/PrintInvoiceLWH?` + params, null);

export const GetCategoryReport = (params: any) =>
    get(`${BASE_URL}/api/Report/CategoryReport?` + params, null);

export const GetTransporterReport = (params: any) =>
    get(`${BASE_URL}/api/Report/TransporterReport?` + params, null);

export const GetWarehouseReport = (params: any) =>
    get(`${BASE_URL}/api/Report/WarehouseReport?` + params, null);

export const GetLedgerSummaryReport = (params: any) =>
    get(`${BASE_URL}/api/Report/Getgroupsummaryreport?` + params, null);

export const GetLedgerMonthlySummaryReport = (params: any) =>
    get(`${BASE_URL}/api/Report/Getmonthlysummaryreport?` + params, null);

export const GetLedgerVoucherReport = (params: any) =>
    get(`${BASE_URL}/api/Report/GetLedgervoucherreport?` + params, null);

export const GetBalanceSheetReport = (params: any) =>
    get(`${BASE_URL}/api/Report/GetBalanceSheetReport?` + params, null);

export const GetDayBookReport = (params: any) =>
    get(`${BASE_URL}/api/Report/GetDayBookReport?` + params, null);

export const GetProfitAndLossReport = (params: any) =>
    get(`${BASE_URL}/api/Report/GetProfitAndLossReport?` + params, null);

export const GetTrialBalanceReport = (params: any) =>
    get(`${BASE_URL}/api/Report/GetTrialBalanceReport?` + params, null);

export const GetPurchaseReturnReport = (params: any) =>
    get(`${BASE_URL}/api/Report/PurchaseReturnReport?` + params, null);

export const GetSaleReturnReport = (params: any) =>
    get(`${BASE_URL}/api/Report/SaleReturnReport?` + params, null);

export const GetEWayBillDriverCopy = (params: any) =>
    get(`${BASE_URL}/api/Report/EWayBillDriverCopy?` + params, null);

export const GetEWayBillOriginalCopy = (params: any) =>
    get(`${BASE_URL}/api/Report/EWayBillOriginalCopy?` + params, null);

export const GetLedgerVoucherForAreawiseReport = (params: any) =>
    get(`${BASE_URL}/api/Report/GetLedgerVoucherForAreaReport?` + params, null);

export const GetStockSummaryBySupplier = (params: any) =>
    get(`${BASE_URL}/api/Report/StockSummaryBySupplier?` + params, null);

export const GetStockSummaryByBuyer = (params: any) =>
    get(`${BASE_URL}/api/Report/StockSummaryByBuyer?` + params, null);

export const GetStockDetailsByBuyer = (params: any) =>
    get(`${BASE_URL}/api/Report/StockDetailsByBuyer?` + params, null);

export const GetStockSummaryByProductAndBuyer = (params: any) =>
    get(`${BASE_URL}/api/Report/StockSummaryByProductAndBuyer?` + params, null);

export const GetFreeGoodsDetailByBuyer = (params: any) =>
    get(`${BASE_URL}/api/Report/FreeGoodsDetailByBuyer?` + params, null);

export const GetFreeGoodsSummaryByBuyer = (params: any) =>
    get(`${BASE_URL}/api/Report/FreeGoodsSummaryByBuyer?` + params, null);

export const GetFreeGoodsSummaryByCategory = (params: any) =>
    get(`${BASE_URL}/api/Report/FreeGoodsSummaryByCategory?` + params, null);

export const GetQuotationDetailDataReport = (params: any) =>
    get(`${BASE_URL}/api/Report/GetQuotationDetailDataReport?` + params, null);

export const GetPurchaseDetailDataReport = (params: any) =>
    get(`${BASE_URL}/api/Report/GetPurchaseDetailDataReport?` + params, null);

export const GetGSTR2Report = (params: any) =>
    get(`${BASE_URL}/api/Report/GSTR2Report?` + params, null);

export const GetGSTR3BReport = (params: any) =>
    get(`${BASE_URL}/api/Report/GSTR3BReport?` + params, null);

export const GetPrintLedgerList = (params: any) =>
    get(`${BASE_URL}/api/Report/GetLedgerReport?` + params, null);

export const GetStockBatchWiseListReport = (params: any) =>
    get(`${BASE_URL}/api/Report/StockBatchWiseListReport?` + params, null);


export const GetStockExpiredListRerport = (params: any) =>
    get(`${BASE_URL}/api/Report/StockExpiredListRerport?` + params, null);


export const GetNearExpiryListRerport = (params: any) =>
    get(`${BASE_URL}/api/Report/NearExpiryListRerport?` + params, null);


export const GetLowQuantityListRerport = (params: any) =>
    get(`${BASE_URL}/api/Report/LowQuantityListRerport?` + params, null);


export const GetSaleSummaryBySalePersonReport = (params: any) =>
    get(`${BASE_URL}/api/Report/GetSaleSummaryBySalePersonReport?` + params, null);

export const GetSaleSummaryByCustomerReport = (params: any) =>
    get(`${BASE_URL}/api/Report/GetSaleSummaryByCustomerReport?` + params, null);


export const GetSaleDetailsByCustomerWiseReport = (params: any) =>
    get(`${BASE_URL}/api/Report/GetSaleDetailsByCustomerWiseReport?` + params, null);


export const GetSaleSummaryByProductReport = (params: any) =>
    get(`${BASE_URL}/api/Report/GetSaleSummaryByProductReport?` + params, null);

export const GetSaleDetailsByProductReport = (params: any) =>
    get(`${BASE_URL}/api/Report/GetSaleDetailsByProductReport?` + params, null);

export const GetPurchaseSummaryBySupplierReport = (params: any) =>
    get(`${BASE_URL}/api/Report/GetPurchaseSummaryBySupplierReport?` + params, null);


export const GetPurchaseDetailsBySupplierReport = (params: any) =>
    get(`${BASE_URL}/api/Report/GetPurchaseDetailsBySupplierReport?` + params, null);

export const GetPurchaseSummaryByProductReport = (params: any) =>
    get(`${BASE_URL}/api/Report/GetPurchaseSummaryByProductReport?` + params, null)


export const GetPurchaseDetailsByProductReport = (params: any) =>
    get(`${BASE_URL}/api/Report/GetPurchaseDetailsByProductReport?` + params, null);

export const GetStockWarehouseByReport = (params: any) =>
    get(`${BASE_URL}/api/Report/GetStockWarehouseWiseReport?` + params, null);


export const GetCashBookPartyWiseListReport = (params: any) =>
    get(`${BASE_URL}/api/Report/GetCashbookPartyWiseReport?` + params, null);