import React from 'react';
import { getStorage } from './Services/common/Storage';
import { ContextModel } from './shared/Modal/ContextModel';
//import useLocalStorage from './Services/common/LocalStorage';


function getUserContext()
{
  return getStorage();
}

const defaultPreferenceState = {
  userContext: getUserContext(),
  updateUserContext: (context: ContextModel) =>  {
        //console.log('call prefrence ',context)
      },
};

export const PreferencesContext = React.createContext(defaultPreferenceState);

