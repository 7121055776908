import React from "react";
// import BillingLogo from "../Components/BillingLogo";
// import Fade from "@mui/material/Fade";
import LinearProgress from '@mui/material/LinearProgress';
export default function SplashComponent() {
    return (
        // <div className="splash-div" style={{width:'100%'}}>
        //     {/* <BillingLogo className="logo animate-flicker" size={80} /> */}

        // </div>
        <div
            //     className="splash-div" 
            style={{
                position: 'fixed',
                top: '50%',
                width: '100%',
                height: '100%'
            }}>
            <div style={{ width: '50%', position: 'relative', left: '25%' }}>
                <LinearProgress color="primary" />
            </div>
        </div>
    );
}