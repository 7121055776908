import { get, post } from "./common/http";
import { BASE_URL } from "./common/const";

export const GetPurchaseDetail = (params: any) =>
  get(`${BASE_URL}/api/Purchase/GetDetail?` + params, null);

export const GetPurchaseByDebit = (params: any) =>
  get(`${BASE_URL}/api/Purchase/GetPurchaseByDebit?` + params, null);

export const GetPurchaseList = (params: any) =>
  get(`${BASE_URL}/api/Purchase/GetList?` + params, null);

export const GetPurchaseDetailDataList = (params: any) =>
  get(`${BASE_URL}/api/Purchase/GetPurchaseDetailDataList?` + params, null);

export const GetPurchaseListBySupplier = (params: any) =>
  get(`${BASE_URL}/api/Purchase/GetListBySupplier?` + params, null);

export const CreatePurchase = (data: any) =>
  post(`${BASE_URL}/api/Purchase/Create`, data, true);

export const UpdatePurchase = (data: any) =>
  post(`${BASE_URL}/api/Purchase/Update`, data, true);

export const DeletePurchase = (data: any) =>
  post(`${BASE_URL}/api/Purchase/Delete`, data);

export const getPurchaseReportExportFile = (params) =>
  get(`${BASE_URL}/api/Purchase/PurchaseExportFile?` + params, null);

export const GetPurchaseListForPayment = (params: any) =>
  get(`${BASE_URL}/api/Purchase/GetPurchaseListForPayment?` + params, null);

export const GetVoucherNumber = (params: any) =>
  get(`${BASE_URL}/api/Purchase/GetVoucherNumber?` + params, null);


export const GetPrintBarcode = (params: any) =>
  get(`${BASE_URL}/api/Purchase/GetPrintBarcode?` + params, null);

export const GetAdvancePaymentListForPurchase = (params: any) =>
  get(`${BASE_URL}/api/Purchase/GetAdvancePaymentListForPurchase?` + params, null);

export const GetPurchaseSummaryListBySupplier = (params:any) => 
    get(`${BASE_URL}/api/Purchase/GetPurchaseSummeryBySupplier?` + params,null)

export const GetPurchaseDetailsListBySupplier = (params:any) => 
  get(`${BASE_URL}/api/Purchase/GetPurchaseDetailsBySupplier?` + params,null)

export const GetPurchaseSummaryByProduct = (params:any) =>
  get(`${BASE_URL}/api/Purchase/GetPurchaseSummeryByProduct?` + params,null)


export const GetPurchaseDetailsListByProduct = (params:any) => 
  get(`${BASE_URL}/api/Purchase/GetPurchaseDetailsByProduct?` + params,null)
