import { get, post } from "./common/http";
import { BASE_URL } from "./common/const";

export const GetList = (params: any) =>
  get(`${BASE_URL}/api/Stock/GetList?` + params, null);

export const getStockReportExportFile = (params: any) =>
  get(`${BASE_URL}/api/Stock/StockExportFile?` + params, null);

export const GetStockSummaryBySupplierList = (params: any) =>
  get(`${BASE_URL}/api/Stock/StockSummaryBySupplierList?` + params, null);

export const GetStockSummaryByBuyerList = (params: any) =>
  get(`${BASE_URL}/api/Stock/StockSummaryByBuyerList?` + params, null);

export const GetStockDetailByBuyerList = (params: any) =>
  get(`${BASE_URL}/api/Stock/StockDetailByBuyerList?` + params, null);

export const GetStockSummaryByProductAndBuyerList = (params: any) =>
  get(`${BASE_URL}/api/Stock/StockSummaryByProductAndBuyerList?` + params, null);

export const GetStockByCategoryList = (params: any) =>
  get(`${BASE_URL}/api/Stock/StockByCategoryList?` + params, null);

export const GetStockAdjList = (params: any) =>
  get(`${BASE_URL}/api/StockAdjustment/GetList?` + params, null);

export const CreateStockAdjustment = (data: any) =>
  post(`${BASE_URL}/api/StockAdjustment/Create`, data)

export const UpdateStockAdjustment = (data: any) =>
  post(`${BASE_URL}/api/StockAdjustment/Update`, data);

export const GetStockAdjustmentDetail = (params: any) =>
  get(`${BASE_URL}/api/StockAdjustment/GetDetail?` + params, null);

export const GetStockAdjNumber = (params: any) =>
  get(`${BASE_URL}/api/StockAdjustment/GetStockAdjNumber?` + params, null);

export const DeleteStockAdjustment = (data: any) =>
  post(`${BASE_URL}/api/StockAdjustment/Delete`, data);

export const GetStockBatchWiseList = (params: any) =>
  get(`${BASE_URL}/api/Stock/StockBatchWiseList?` + params, null);

export const GetExpiredStock = (params: any) =>
  get(`${BASE_URL}/api/Stock/stockExpiredList?` + params, null);


export const GetNearExpirydStock = (params: any) =>
  get(`${BASE_URL}/api/Stock/NearExpirydStock?` + params, null);


export const GetLowQuantityStock = (params: any) =>
  get(`${BASE_URL}/api/Stock/LowQuantity?` + params, null);

export const GetStockByWarehouseList = (params: any) =>
  get(`${BASE_URL}/api/Stock/StockByWarehouseList?` + params, null);