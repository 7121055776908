import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { useContext } from 'react';
import { PreferencesContext } from '../PreferencesContext';
import { ContextModel } from '../shared/Modal/ContextModel';
import { useNavigate } from 'react-router-dom';
import { getStorage } from '../Services/common/Storage';
import { Grid } from '@mui/material';

export default function UnauthorizeDialog(props: { isOpen: boolean; }) {
  const { userContext, updateUserContext } = useContext(PreferencesContext);
  let navigate = useNavigate();
  let value = getStorage();
  const handleClose = (event, reason) => {
    if (reason && reason == "backdropClick")
      return;
    let contextModel = new ContextModel();
    updateUserContext(contextModel);
    navigate("/");
  };
  const handlelogin = () => {
    let contextModel = new ContextModel();
    updateUserContext(contextModel);
    navigate("/");
  };
  return (
    <div>
      <Dialog
        open={value.isUnauthorized}
        onClose={handleClose}
        fullWidth     
      >
        <DialogContent style={{minHeight:200, paddingTop:50}}>
          <Grid container>
            <Grid item xs={12} sx={{display:'flex', justifyContent:'center'}}>
              <img src='/session.svg'/>
            </Grid>
            <Grid item xs={12} sx={{display:'flex', justifyContent:'center'}}>
              <h3 style={{paddingTop:20}}>
                Your session has expired
              </h3>
            </Grid>
            <Grid item xs={12} sx={{display:'flex', justifyContent:'center'}}>
               <h4 className='sub-header'>
                Please sign in again to start your everyday work.
               </h4>
            </Grid>
            <Grid item xs={12}></Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{justifyContent:'center', paddingBottom:8}}>
          <Button
            color="primary"
            variant="contained"
            className='button-primary'
            onClick={handlelogin}>
            Sign In
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
