export enum GSTType {
  None = 0,
  WithInState = 1,
  IntraState = 2,
}
export enum InvoiceType {
  AllInvoice = 0,
  TaxInvoice = 1,
  Proforma = 2,
  Quotation = 3,
  LabourInvoice = 101,
  CompositionInvoice = 102
}


export enum JEType {
  AllJE = 0,
  Payment = 1,
  Receipt = 2,
  Contra = 3,
  JournalVoucher = 4
}


export enum ChallanType {
  Challan = 1,

}