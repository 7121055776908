import React, { useContext } from "react";
import { ContextModel } from "../../shared/Modal/ContextModel";
import { PreferencesContext } from '../../PreferencesContext';
import { getStorage, getVersion, setStorage, setVersion } from "./Storage";
import { MAINTENANCE_URL } from "./const";
//import useLocalStorage from "../common/LocalStorage";
//
//

let token = "";
// var promise = PreferencesContext;
// promise.then(function (value:any) {
//   token = value && (value.token ? value.token : "");
// });


// const { updateUserContext , userContext } = React.useContext(PreferencesContext);

export const post = (url: string, data: any = null, isFormData: boolean = false, fileToken: string = "") =>

  new Promise((resolve, reject) => {
    let localStorage = getStorage();
    let curVersion = getVersion();
    if (localStorage) {
      token = localStorage && (localStorage.token ? localStorage.token : "");
    }
    else {
      token = "";
    }

    let options = {
      headers: {
      },
    };

    if (isFormData === true) {
      options.headers = {
        Authorization: token ? `Bearer ${token}` : "",
        Accept: "*/*"
      };
    } else {
      options.headers = {
        Authorization: token ? `Bearer ${token}` : "",
        Accept: "application/json",
        "Content-Type": "application/json"
      };
    }


    let postOptions = { ...options.headers };

    if (fileToken.length > 0) {
      postOptions = {
        ...options.headers,
        Authorization: fileToken ? `Bearer ${fileToken}` : "",
      };
    }

    return (
      fetch(url, {
        method: "POST",
        headers: postOptions,
        body: (isFormData === false) ? JSON.stringify(data) : data,
      })
        .then((response) =>
          response.json().then((body) => (
            {
              ok: response.ok,
              status: response.status,
              statusText: response.statusText,
              header: response.headers,
              data: body,
            }))
        )
        .then((responseJson) => {
          //resolve(JSON.parse(responseJson));
          let apiVersion = responseJson.header.get('X-Version')!.toString();
          let isLogout = responseJson.header.get('X-Access')!.toString();
          if (curVersion !== null && curVersion !== undefined && curVersion !== "") {
            if (+apiVersion !== +curVersion) {
              setVersion(responseJson.header.get('X-Version')!.toString());
              caches.keys().then((names) => {
                names.forEach((name) => {
                  caches.delete(name);
                });
              });
              window.location.reload();
              if (isLogout === "true") {
                //remove local storage
                let curuserInfo: ContextModel = new ContextModel();
                setStorage(curuserInfo);
              }
            }
          } else {
            setVersion(apiVersion);
            caches.keys().then((names) => {
              names.forEach((name) => {
                caches.delete(name);
              });
            });
            window.location.reload();
          }
          if (!responseJson.ok) {
            if (responseJson.status === 400) {
              //400 = bad request
              if (responseJson.data && responseJson.data.message)
                throw responseJson.data.message;
              else throw responseJson.statusText;
            } else throw responseJson.statusText;
          } else resolve(responseJson.data);
        })
        .catch((error) => {
          // window.location.href = MAINTENANCE_URL;
          reject(error);
        })
    );
  });
export const get = (url: any, params: any) =>
  new Promise((resolve, reject) => {
    let localStorage = getStorage();
    let curVersion = getVersion();
    if (localStorage) {
      token = localStorage && (localStorage.token ? localStorage.token : "");
    }
    else {
      token = "";
    }

    let options = {
      headers: {

        Authorization: token ? `Bearer ${token}` : "",
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    return fetch(url, { method: "GET", ...options })
      .then((response) =>
        response.json().then((body) => ({
          ok: response.ok,
          status: response.status,
          statusText: response.statusText,
          header: response.headers,
          data: body,
        }))
      )
      .then((responseJson) => {

        let apiVersion = responseJson.header.get('X-Version')!.toString();
        let isLogout = responseJson.header.get('X-Access')!.toString();
        if (curVersion !== null && curVersion !== undefined && curVersion !== "") {
          if (+apiVersion !== +curVersion) {
            setVersion(responseJson.header.get('X-Version')!.toString());
            caches.keys().then((names) => {
              names.forEach((name) => {
                caches.delete(name);
              });
            });
            window.location.reload()
            if (isLogout === "true") {
              //remove local storage
              let curuserInfo: ContextModel = new ContextModel();
              setStorage(curuserInfo);
            }
          }
        } else {
          setVersion(apiVersion);
          caches.keys().then((names) => {
            names.forEach((name) => {
              caches.delete(name);
            });
          });
          window.location.reload();
        }
        resolve(responseJson);

        if (!responseJson.ok) {
          if (responseJson.status === 400) {
            //400 = bad request
            if (responseJson.data && responseJson.data.message)
              throw responseJson.data.message;
            else throw responseJson.statusText;
          } else throw responseJson.statusText;
        } else resolve(responseJson.data);
      })
      .catch((error) => {
        // window.location.href = MAINTENANCE_URL;
        reject(error);
      });
  });