import { get, post} from "./common/http";
import { BASE_URL } from "./common/const";


    export const Login = (data: any) =>
    post(`${BASE_URL}/api/Auth/Login`, data);

    export const ForgotPassword = (data: any) =>
    post(`${BASE_URL}/api/Auth/ForgotPassword`, data);

    export const ResetPassword = (data: any) =>
    post(`${BASE_URL}/api/Auth/ResetPassword`, data); 

    export const SetPassword = (data: any) =>
    post(`${BASE_URL}/api/Auth/SetPassword`, data); 
    
    export const CheckIsExpireInvitation = (data: any) =>
        post(`${BASE_URL}/api/Auth/CheckIsExpireInvitation`, data); 
    
    export const RefreshToken = (data: any) =>
    post(`${BASE_URL}/api/Auth/RefreshToken`, data); 
    
    export const Info = (params: any) =>
    get(`${BASE_URL}/api/Auth/Info?` + params, null);

