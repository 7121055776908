import { useEffect, useRef } from "react";

export default function CustomAutoFocus(props: any, shouldFocus: boolean) {
    const inputRef = useRef<HTMLInputElement | null>(null);
    const setInputRef = (instance: HTMLInputElement | null) => {
        inputRef.current = instance;
    };
    useEffect(() => {
        let timeout: any;
        if (props !== undefined && props) {
            if (shouldFocus) {
                timeout = setTimeout(() => {
                    inputRef.current?.focus();
                });
            }
        }
        return () => {
            if (timeout) {
                clearTimeout(timeout);
            }
        };
    }, [shouldFocus]);

    return {
        setInputRef,
    };
}
