import { get, post } from "./common/http";
import { BASE_URL } from "./common/const";

// export const GetDetail = (data: any) =>
//   post(`${BASE_URL}/api/Category/GetDetail`, data);

export const GetProductTypeList = () =>
  get(`${BASE_URL}/api/Product/GetListForProductType`, null);

export const GetProductDetail = (params: any) =>
  get(`${BASE_URL}/api/Product/GetDetail?` + params, null);

export const GetLastProductDetail = (params: any) =>
  get(`${BASE_URL}/api/Product/GetLastProduct?` + params, null);

export const GetProductListforBatchwiseListReport = (params: any) =>
  get(`${BASE_URL}/api/Product/GetListForBatchWise?` + params, null);

export const GetProductList = (params: any) =>
  get(`${BASE_URL}/api/Product/GetList?` + params, null);

export const GetProductListForNotes = (params: any) =>
  get(`${BASE_URL}/api/Product/GetListForNotes?` + params, null);

export const GetProductListForSale = (params: any) =>
  get(`${BASE_URL}/api/Product/GetListForSale?` + params, null);

export const GetProductListForStockAdj = (params: any) =>
  get(`${BASE_URL}/api/Product/GetListForStockAdj?` + params, null);

export const CreateProduct = (data: any) =>
  post(`${BASE_URL}/api/Product/Create`, data, true);


export const UpdateProduct = (data: any) =>
  post(`${BASE_URL}/api/Product/Update`, data, true);

export const DeleteProduct = (data: any) =>
  post(`${BASE_URL}/api/Product/Delete`, data);

export const GetProductBarcode = (params: any) =>
  get(`${BASE_URL}/api/Product/GetProductBarcode?` + params, null);

export const GenerateProductBarcode = (params: any) =>
  get(`${BASE_URL}/api/Product/GenerateProductBarcode?` + params, null);

export const GetFreeGoodSummaryByCategoryList = (params: any) =>
  get(`${BASE_URL}/api/Product/FreeGoodSummaryByCategoryList?` + params, null);

export const GetFreeGoodsDetailByBuyerList = (params: any) =>
  get(`${BASE_URL}/api/Product/FreeGoodsDetailByBuyerList?` + params, null);

export const GetFreeGoodsSummaryByBuyerList = (params: any) =>
  get(`${BASE_URL}/api/Product/FreeGoodsSummaryByBuyerList?` + params, null);
