import React, { useEffect } from 'react';
import { SITE_TITLE } from '../Services/common/const';


export default function Title(props: {pageTitle: string,}) {

    function getSiteTitle() {
        let fullSiteName = SITE_TITLE;
        
        if(props.pageTitle && props.pageTitle.length > 0)
        {
            fullSiteName = props.pageTitle + " - "  + fullSiteName;
        }
        return fullSiteName;
      }

      useEffect(() => {
        const prevTitle = document.title;
        document.title = getSiteTitle();
        return () => {
          document.title = prevTitle;
        };
      }, []);

    return <></>;
  }